import React from 'react'
import '../Assets/StyleSheets/Home.scss'
import bulb from '../Assets/Images/Bulb full.jpg'
import { ImMail } from "react-icons/im";
import { BiLogoGmail } from "react-icons/bi";
import { IoIosMail } from "react-icons/io";

export default function Home() {
    return (
        <div className='content'>
            <div className='top-section'>Management Simplified</div>
            <div className='center-section'>
                <img src={bulb} />
            </div>
            <div className='bottom-section  w-100'>
                <div className='heading'>
                    Quadnomics
                </div>
                <div className='mail-section'>
                
                {/* <ImMail size={25}/> */}
                {/* <BiLogoGmail size={25}/> */}
                <IoIosMail size={30}/>

             query@quadnomics.in
                </div>
            </div>


        </div>
    )
}
